import React, { useContext } from 'react';
import { close } from '../../../../assets/images/Global';
import PaymentContext from '../../../../context/Payment/Context';
import CreateAddress from './components/CreateAddress';
import styles from './AddressModal.module.css';
import { data } from './data';
import EditAddress from './components/EditAddress';
import GlobalContext from '../../../../context/Global/Context';

const AddressModal = () => {
  const paymentContext = useContext(PaymentContext);
  const { modal, hasAddress, showCloseAddressModal } = paymentContext;
  const globalContext = useContext(GlobalContext);
  const {
    isCitaWithCPG,
    isOnlyCPG,
    country,
  } = globalContext;
  const { titleEdit, titleNonDelirable } = data[country] || data.Default;
  const titleAddressModal = (isCitaWithCPG || isOnlyCPG)
    ? titleNonDelirable : titleNonDelirable;
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <p className={styles.title}>{hasAddress ? titleEdit : titleAddressModal}</p>
          <button
            id="Check_Address_CloseCreateAddress"
            className={styles.closeButton}
            onClick={() => {
              showCloseAddressModal(!modal);
            }}
            type="button"
          >
            <img src={close} alt="Close Button" />
          </button>
        </div>
        {hasAddress ? <EditAddress /> : <CreateAddress />}
      </div>
      <button
        id="Check_Address_CloseCreateAddress_HiddenButton"
        onClick={() => {
          showCloseAddressModal(!modal);
        }}
        type="button"
        aria-label="area"
        className={styles.hiddenButton}
      />
    </div>
  );
};

export default AddressModal;
