/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import {
  products1x,
  productMoons1x,
  productMoons2x,
  productMoons3x,
} from '../../assets/images/Global';
import { Box, Text, CardProduct } from '../../components';
import useMediaQuery from '../../hooks/useMediaQuery';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import ResumeModalMobile from './components/ResumeModalMobile';
import styles from './Summary.module.css';
import data from './data.json';
import useProductDataByCountry from '../../hooks/useProductDataByCountry';
import totalPriceCarts from '../../hooks/totalCarts';
import Coupon from './components/Coupon';
import convertPrice from '../../hooks/convertPrice';
import { getTotalPrice, getTotalPriceFormatted } from '../../utils/cardForm';
import { availableCountries } from '../../utils/locale';
import { axiosGetOrderId } from '../../methods/globalData/axiosGet';

const Summary = () => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { title } = data;
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const {
    productsMobile,
    setTotalPrice,
    setHasMultiplePayments,
  } = paymentContext;
  const { carts, products } = productContext;
  const {
    appointmentCenter,
    appointmentDate,
    country,
  } = globalContext;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;
  const [productsToShow, setProductsToShow] = useState([]);
  const [originalPrice, setOriginalPrice] = useState(0);
  const totalCarts = totalPriceCarts(carts, true);
  const { lineItems } = carts[0];
  let cost = 0;

  useEffect(() => {
    if (carts[0].cartState === 'Active') {
      setTotalPrice(totalCarts);
    } else if (carts[0].cartState === 'Ordered') {
      axiosGetOrderId(carts[0].id).then((res) => {
        let custom = null;
        if (res.data.results[0].custom && res.data.results[0].custom.fields) {
          custom = res.data.results[0].custom.fields;
          if (custom.remanent) {
            setTotalPrice(`$${custom.remanent}`);
          }
          if (custom.hasMultiplePayments) {
            setHasMultiplePayments(true);
          }
        }
      });
    }
  }, [totalCarts]);

  useEffect(() => {
    lineItems.forEach((item) => {
      if (item.price.discounted) {
        cost = +cost + (item.price.discounted.value.centAmount / 100) * item.quantity;
      } else {
        cost = +cost + (item.price.value.centAmount / 100) * item.quantity;
      }
      const priceConv = convertPrice(Number(originalPrice + cost).toFixed(2));
      setOriginalPrice(priceConv);
    });
  }, [lineItems]);

  // TODO The following lines can be handler in the product context
  if (productsToShow.length < products.length) {
    const converted = useProductDataByCountry(products, true, true);
    setProductsToShow(converted);
  }

  useEffect(() => {
    if (productsMobile) {
      $html.style.height = '100vh';
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      return;
    }

    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, [productsMobile]);

  return (
    <div className={styles.containerMain}>
      <Box fd="column" ai="flex-start" p={isMobile ? '0' : '0'}>
        <link rel="preload" as="image" href={products1x} src={products1x} />
        {!isMobile && (
          <>
            <h2 className={styles.title}>{title}</h2>
            <Box fd="row" jc="space-between" p="8px 0 40px 0">
              <Text id="Check_Summary_TotalSummary" f="Poppins Medium" s={32} lh={48}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                { getTotalPrice(paymentContext, country) }
              </Text>
            </Box>
            <Box>
              {productsToShow.map((product) => {
                return (
                  <CardProduct
                    key={product.name}
                    img1x={
                      product.images[0] ? product.images[0].url : productMoons1x
                    }
                    img2x={
                      product.images[1] ? product.images[1].url : productMoons2x
                    }
                    img3x={
                      product.images[2] ? product.images[2].url : productMoons3x
                    }
                    title={'Alineadores Full - ' + product.name}
                    principalPrice={getTotalPriceFormatted(
                      product.totalPrice,
                      country,
                    )}
                    quantity={product.quantity}
                    secondaryPrice={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}${getTotalPriceFormatted(product.totalPrice, country)} por unidad`}
                    isPromo={product.hasDiscount}
                    isCitaFlow={(product.productId === process.env.REACT_APP_CITA_ID
                      || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL) && true}
                    appointmentDate={
                      (product.productId === process.env.REACT_APP_CITA_ID
                        || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL)
                      && appointmentDate
                    }
                    appointmentCenter={
                      (product.productId === process.env.REACT_APP_CITA_ID
                        || product.productId === process.env.REACT_APP_CITA_ID_LIVERPOOL)
                      && appointmentCenter
                    }
                    countryCurrency={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}`}
                  />
                );
              })}
            </Box>
            <div className={styles.line} />
            <Box>
              <Box fd="row" jc="space-between" p="0 0 16px 0">
                <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                  Total en tu carrito
                </Text>
                <Text id="Check_Summary_TotalOriginalPrice" c="#757575" f="Inter Regular" s={14} lh={20}>
                  {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                  { getTotalPrice(paymentContext, country) }
                </Text>
              </Box>
            </Box>
            <Coupon />
            <div className={styles.line} />
            <Box fd="row" jc="space-between">
              <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                Total
              </Text>
              <Text id="Check_Summary_TotalWithDiscount" c="#616161" f="Inter Bold" s={14} lh={20}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                {getTotalPrice(paymentContext, country)}
                {' '}
                {country.toLocaleLowerCase() !== 'pe' ? availableCountries[country.toLocaleLowerCase()].currency : ''}
              </Text>
            </Box>
          </>
        )}
        {isMobile && (
          <ResumeModalMobile />
        )}
      </Box>
    </div>
  );
};

export default Summary;
