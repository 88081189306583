/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import PaymentContext from '../../../../context/Payment/Context';
import { priceCheck } from '../../../../assets/images/Global';
import AlertModal from '../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../utils/stock';
import GlobalContext from '../../../../context/Global/Context';
import SliderButton from './components/SliderButton';
import dataPayments from './dataPayments';
import styles from './MethodsFlow.module.css';

const MethodsFlow = (props) => {
  const totalPrice = props?.totalPrice || 0
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const { dashboard } = paymentContext;
  const { country } = globalContext;
  const { hasMultiplePayments } = paymentContext;
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  
  const [preventModal, setPreventModal] = useState(false);
  const [payments, setPayments] = useState(dataPayments[country].payments);

  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  useEffect(() => {
    const noShowForMultiplePayments = ['oxxo', 'spei', 'efecty', 'cash', 'pse', 'efectivo'];
    if (hasMultiplePayments) {
      const updatedPayments = dataPayments[country].payments.map(method => ({
        ...method,
        show: !noShowForMultiplePayments.includes(method.type)
      }));
      setPayments(updatedPayments);
    } else {
      const updatedPayments = dataPayments[country].payments.map(method => ({
        ...method
      }));
      setPayments(updatedPayments);
    }
  }, [hasMultiplePayments]);

  useEffect(() => {
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (!areThereCartsWithoutEnoughStock(upToDateCartData.carts)) return;
        setPreventModal(true);
      } catch {
        // eslint-disable-next-line no-console
        setPreventModal(false);
      }
    })();
  }, [dashboard]);

  return (
    <>
      {totalPrice === 0 ? dataPayments[country]['closeOrder']() : (
        <>
          <p className={styles.label}>¿Cómo deseas pagar?</p>
          <div className={styles.containerSlider}>
            {payments.map((method, index) => (
              method.show && (
                <SliderButton key={method.type} data={method} index={index} />
              )
            ))}
          </div>
          {dataPayments[country][dashboard]()}
        </>
      )}
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
    </>
  );
};

export default MethodsFlow;
