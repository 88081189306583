/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from '@mymoons/error-page-infinity';
import { PageLoader } from '@mymoons/ui-library';
import Header from '../../components/Header';
import Dashboard from '../../containers/Dashboard';
import { axiosGet, axiosGetOrderId } from '../../methods/globalData/axiosGet';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import Constants from '../../utils/Constants';
import AlertModal from '../../components/AlertModal';
import { priceCheck, timer, backCheckout } from '../../assets/images/Global';
import { axiosPutRecalculateCarts } from '../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../utils/stock';
// import { axiosPutSetShippingMethod } from '../../methods/globalData/axiosPut';
import alertModalInfo from './alertModalInfo';
import { getPatientDataBack4app, getSmileCenterBack4app } from '../../utils/back4app';
import dateFormatter from '../../utils/dateFormatter';
import { processDiscountCodes } from '../../utils/discounts';
import { setTrackingRudder } from '../../utils/tracking';

class CustomErrorMessage extends Error {
  constructor(message, description) {
    super(message, description);
    this.name = `${description}`;
  }
}

const Home = () => {
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const name = new URLSearchParams(window.location.search).get('name');
  const redirectUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl',
  );
  const publicKey = new URLSearchParams(window.location.search).get(
    'redirectUrl',
  );
  const origin = new URLSearchParams(window.location.search).get(
    'origin',
  );
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'buttonUrl',
  );
  const customerId = new URLSearchParams(window.location.search).get(
    'customerId',
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [getCartsData, setGetCartsData] = useState([]);
  const [Items, setItems] = useState([]);
  const [cartOrdered, setCartOrdered] = useState(false);
  const [addressToModal, setAddressToModal] = useState([]);
  const [orderedType, setOrderedType] = useState('');
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const {
    setOriginFunction,
    setRedirectUrlFunction,
    setPublicKeyFunction,
    setIsCitaFlowFunction,
    setButtonUrlFunction,
    setCustomerIdFunction,
    setCartCountryFunction,
    setAppointmentDateFunction,
    setAppointmentCenterFunction,
    setIsCitaWithCPGFunction,
    setAppointmentRedirectFunction,
    setIsOnlyCPGFuntction,
    setIsAlignersFlowFunction,
    setIsOnlySettlementFunction,
    setIsOnlyAdvanceFunction,
    setIsOnlyComplementaryPaymentFunction,
    setIsInfinity,
    setNameFunction,
    setEmailFunction,
    isCitaFlow,
    isInfinity,
    country,
    publicKey: publicKeyContext,
    customerId: customerIdContext,
    setInfinityCenter,
  } = globalContext;
  const {
    setCarts,
    setProducts,
    setCoupons,
    setCouponsCode,
  } = productContext;
  const {
    CitaId,
    LiverpoolCitaId,
    // ShippingId,
    Apartado,
    PagoComplementario,
  } = Constants;

  /**
   * This function returns all Modal Data
   */
  const modalStrategies = alertModalInfo(
    isCitaFlow,
    priceCheck,
    buttonUrl,
    publicKey,
    redirectUrl,
    timer,
    addressToModal,
    backCheckout,
    setCartOrdered,
    isInfinity,
  );

  const setInfinityInfo = (patient) => {
    const centerType = 'Doctores Moons';
    localStorage.setItem('isInfinity', false);
    const calendarId = patient?.Calendar_Id;
    getSmileCenterBack4app(calendarId)
      .then((res) => {
        const smileCenter = res?.data?.SmileCenter;
        const type = smileCenter?.Center_Type;
        setInfinityCenter(smileCenter);
        if (type) {
          if (type === centerType) {
            setIsInfinity(true);
          }
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  const retrySetInfinityInfo = (attempt = 1) => {
    getPatientDataBack4app('Patient', null, null, customerId)
      .then((res) => {
        if (res.data.Patient.Calendar_Id) {
          setInfinityInfo(res.data.Patient);
        } else if (attempt <= 3) {
          setTimeout(() => {
            retrySetInfinityInfo(attempt + 1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log('Error getting Patient Info', err);
        setError(true);
      });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(carts);

        if (!areThereCartsWithoutEnoughStock(upToDateCartData.carts)) return;

        setOrderedType('outOfStock');
        setCartOrdered(true);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        // setError(true);
      }
    })();
    if (customerId) {
      retrySetInfinityInfo();
    }
  }, []);

  useEffect(() => {
    if (country && customerIdContext) {
      setTrackingRudder('page_view_checkout', { platform: 'checkout', country_ops: country, customerId: customerIdContext });
    }
  }, [customerIdContext, country]);

  useEffect(() => {
    if (isCitaFlow && origin !== 'payment-links') {
      setTimeout(() => {
        setOrderedType('timeOut');
        setCartOrdered(true);
      }, 600000);
    }
  }, [isCitaFlow, origin]);

  useEffect(() => {
    const urlGenerated = `carts/${carts[0]}`;
    const newArrayCouponsCode = [];
    axiosGet(urlGenerated, true)
      .then((res) => {
        const { data } = res;
        setEmailFunction(data.customerEmail);
        if (country === '') {
          setCartCountryFunction(data.country.toLocaleUpperCase());
        }
        setGetCartsData([...getCartsData, data]);
        setCoupons(data.discountCodes);
        processDiscountCodes(
          res.data.lineItems,
          res.data.customLineItems,
        ).then((responseCouponsProcess) => {
          responseCouponsProcess.forEach((discount) => {
            newArrayCouponsCode.push({
              id: discount.id,
              code: discount.code,
              discount: discount.discount,
            });
          });
          setCouponsCode(newArrayCouponsCode);
        });
        if (data.lineItems.length !== 0 || data.customLineItems.length !== 0) {
          const arrayItems = [];
          data.customLineItems.forEach((custom) => {
            arrayItems.push(custom);
          });
          data.lineItems.forEach((line) => {
            arrayItems.push(line);
          });
          if (arrayItems.length !== 0) {
            setItems([...Items, arrayItems]);
          }
        }
      })
      .catch((errorSentry) => {
        const errorToSentry = new CustomErrorMessage(errorSentry, 'Error al obtener el carrito');
        if (Sentry) {
          Sentry.captureException(errorToSentry);
        }
        setError(true);
      });
    if (name) {
      localStorage.setItem('name', name);
      setNameFunction(name);
    } else {
      localStorage.setItem('name', 'Usuario Invitado');
      setNameFunction('Usuario Invitado');
    }
    if (redirectUrl) {
      setRedirectUrlFunction(redirectUrl);
    }
    if (publicKey) {
      let publicKeyConverted = publicKey.split('/')[3];
      [publicKeyConverted] = publicKeyConverted.split('?');
      setPublicKeyFunction(publicKeyConverted);
    }
    if (origin) {
      setOriginFunction(origin);
    }
    if (buttonUrl) {
      setButtonUrlFunction(buttonUrl);
    }
    if (customerId) {
      setCustomerIdFunction(customerId);
    }
  }, [name, redirectUrl, publicKey, origin, buttonUrl, customerId]);

  useEffect(() => {
    try {
      if (carts.length === getCartsData.length) {
        const appointmentCenter = new URLSearchParams(window.location.search).get(
          'center',
        );
        setCarts(getCartsData);
        setProducts(Items);
        if (Items.length !== 0) {
          if (Items[0].length === 1) {
            const [[product]] = Items;
            if (product.productId === CitaId || product.productId === LiverpoolCitaId) {
              setIsCitaFlowFunction(true);
              try {
                if (window.dataLayer) {
                  console.log('GA4-checkout-appointment');
                  window.dataLayer.push({ event: 'checkout-appointment' });
                }
              } catch (err) {
                console.log(err);
              }
            } else {
              setIsCitaFlowFunction(false);
            }
            if (product?.custom?.fields?.productKey === 'ALDRS') {
              setIsOnlySettlementFunction(true);
            } else {
              setIsOnlySettlementFunction(false);
            }
            if (product.productId === Apartado) {
              setIsOnlyAdvanceFunction(true);
            } else {
              setIsOnlyAdvanceFunction(false);
            }
            if (product.productId === PagoComplementario) {
              setIsOnlyComplementaryPaymentFunction(true);
            } else {
              setIsOnlyComplementaryPaymentFunction(false);
            }
            // Get Appointment Information
            getPatientDataBack4app('Patient', publicKeyContext, null, customerIdContext)
              .then((responeDealAppointment) => {
                if (responeDealAppointment.data.Patient.Appointment_Date) {
                  const appointmentDate = dateFormatter(
                    responeDealAppointment.data.Patient.Appointment_Date.iso,
                    responeDealAppointment.data.Patient.Timezone,
                    responeDealAppointment.data.Patient.Country_Ops,
                  );
                  setAppointmentDateFunction(appointmentDate);
                  setAppointmentCenterFunction(responeDealAppointment.data.Patient.Calendar);
                } else {
                  setAppointmentCenterFunction(appointmentCenter);
                }
                if (
                  (responeDealAppointment.data.Patient.Appointment_Status
                !== 'Scheduled'
                && responeDealAppointment.data.Patient.Appointment_Status
                !== 'Canceled')
                ) {
                  if (product.productId === CitaId || product.productId === LiverpoolCitaId) {
                    setAppointmentRedirectFunction(true);
                  } else {
                    setAppointmentRedirectFunction(false);
                  }
                }
                if (!publicKeyContext) {
                  setPublicKeyFunction(responeDealAppointment.data.Patient.PublicKey);
                }
              })
              .catch((err) => {
                console.log(err);
                setError(true);
              });
          } else {
            const isOnlyCPG = Items[0].find((item) => {
              return (
                item.productId === Apartado
              || item.productKey === 'ALDRS'
              || item.productId === PagoComplementario
              || item.productId === CitaId
              || item.productId === LiverpoolCitaId
              );
            });
            if (isOnlyCPG === undefined && origin === 'payment-links') {
              setIsOnlyCPGFuntction(true);
            } else {
              setIsOnlyCPGFuntction(false);
            }
            const isAlignersFlow = Items[0].find((item) => {
              return (
                item.productId === Apartado
              || item.productKey === 'ALDRS'
              || item.productId === PagoComplementario
              );
            });
            if (isAlignersFlow !== undefined) {
              setIsAlignersFlowFunction(true);
            } else {
              setIsAlignersFlowFunction(false);
            }
            const isCitaWithCPG = Items[0].find((item) => {
              return (item.productId === CitaId || item.productId === LiverpoolCitaId);
            });
            if (isCitaWithCPG !== undefined) {
              setIsCitaWithCPGFunction(true);
            } else {
              setIsCitaWithCPGFunction(false);
            }
            // Get Appointment Information
            getPatientDataBack4app('Patient', publicKeyContext, null, customerIdContext)
              .then((responeDealAppointment) => {
                if (responeDealAppointment.data.Patient.Appointment_Date) {
                  const appointmentDate = dateFormatter(
                    responeDealAppointment.data.Patient.Appointment_Date.iso,
                    responeDealAppointment.data.Patient.Timezone,
                    responeDealAppointment.data.Patient.Country_Ops,
                  );
                  setAppointmentDateFunction(appointmentDate);
                  setAppointmentCenterFunction(responeDealAppointment.data.Patient.Calendar);
                } else {
                  setAppointmentCenterFunction(appointmentCenter);
                }
                // Typo generado en Appointmentx_Status el 220/09/2022
                // Commit: 52bb35710b653b2f60d5cf03ad6f2236e9f850be [52bb357]
                if (
                  (responeDealAppointment.data.Patient.Appointmentx_Status
                !== 'Scheduled'
                && responeDealAppointment.data.Patient.Appointment_Status
                !== 'Canceled')
                ) {
                  if (isCitaWithCPG !== undefined || isCitaFlow) {
                    setAppointmentRedirectFunction(true);
                  } else {
                    setAppointmentRedirectFunction(false);
                  }
                }
                if (!publicKeyContext) {
                  setPublicKeyFunction(responeDealAppointment.data.Patient.PublicKey);
                }
              }).catch((err) => {
                console.log(err);
                setError(true);
              });
          }
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(true);
    }
  }, [getCartsData, Items]);

  if (addressToModal.length === 0 && getCartsData.length > 0) {
    setAddressToModal(getCartsData[0]);
  }

  useEffect(() => {
    if (getCartsData[0]) {
      axiosGetOrderId(getCartsData[0].id)
        .then((response) => {
          if (response.data.results.length !== 0) {
            setCartOrdered(true);
            if (response.data.results[0].paymentState === 'Paid') {
              setOrderedType('paid');
            } else if (response.data.results[0].paymentState === 'Pending') {
              if (response.data.results[0].custom.fields.hasMultiplePayments) {
                setOrderedType('partial');
              } else {
                setOrderedType('pending');
              }
            }
          }
        })
        .catch((errorOrderId) => {
          // eslint-disable-next-line
          console.log(errorOrderId);
        });
      // TODO: Uncomment when shipping method is ready
      // if (!getCartsData[0].shippingInfo) {
      //   const shippingMethod = {
      //     id: ShippingId,
      //     typeId: 'shipping-method',
      //   };
      //   axiosPutSetShippingMethod(carts, shippingMethod);
      // }
    }
  }, [getCartsData[0]]);

  if (loading && !error) {
    return <PageLoader />;
  }

  if (error || carts.length === 0) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header />
      <Dashboard />
      {cartOrdered && orderedType !== '' && (
        <AlertModal
          data={modalStrategies[orderedType].data}
          modal={modalStrategies[orderedType].modal && setCartOrdered}
          discardOption={modalStrategies[orderedType].discardOption}
        />
      )}
    </>
  );
};

export default Home;
