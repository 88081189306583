import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import Text from '../Text';
import styles from './CardProduct.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';

const CardProducts = ({
  img1x,
  img2x,
  img3x,
  title,
  quantity,
  principalPrice,
  secondaryPrice,
  isPromo,
  isCitaFlow,
  appointmentDate,
  appointmentCenter,
  countryCurrency,
}) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const inter16 = isMobile ? 12 : 16;
  const inter14 = isMobile ? 12 : 14;

  return (
    <Box jc="space-between" p="16px 0 0 0">
      <Box fd="row" jc="flex-start">
        {isMobile ? (
          <picture className={styles.imgProduct}>
            <source type="image/webp" src={img1x} />
            <img
              className={styles.productSummaryImg}
              src={img1x}
              alt="Product"
              height={isMobile ? 56 : 48}
              width={isMobile ? 56 : 48}
            />
          </picture>
        ) : (
          <picture className={styles.imgProduct}>
            <source
              type="image/webp"
              srcSet={`${img1x} 1x, ${img2x} 2x, ${img3x} 3x`}
            />
            <img
              className={styles.productSummaryImg}
              src={img2x}
              alt="Product"
              height={isMobile ? 36 : 48}
              width={isMobile ? 36 : 48}
              srcSet={`${img1x} 1x, ${img2x} 2x, ${img3x} 3x`}
            />
          </picture>
        )}
        <Box>
          <Box jc="space-between" fd="row">
            <Text s={inter14} w={800}>{title}</Text>
            <Text s={inter16}>
              {countryCurrency}
              {principalPrice}
            </Text>
          </Box>
          {isCitaFlow && (
            <Box jc="space-between" fd="row">
              <Text c="#616161" s={inter14}>
                {appointmentCenter}
              </Text>
              <Text
                className={
                  isPromo ? styles.textDecoration : styles.textDecorationError
                }
                c={isPromo ? '#F38FA6' : '#9E9E9E'}
                s={inter14}
              >
                {secondaryPrice && secondaryPrice}
              </Text>
            </Box>
          )}
          {isCitaFlow && (
            <Box jc="space-between" fd="row">
              <Text c="#616161" s={inter14}>
                {appointmentDate}
              </Text>
            </Box>
          )}
          <Box jc="space-between" fd="row">
            <Text c="#9E9E9E" s={inter14} lh={20}>
              {!isCitaFlow && 'Cant.'}
              {!isCitaFlow && <Text s={inter14}>{quantity}</Text>}
            </Text>
            {!isCitaFlow && (
              <Text
                className={
                  isPromo ? styles.textDecoration : styles.textDecorationError
                }
                c={isPromo ? '#F38FA6' : '#9E9E9E'}
                s={inter14}
              >
                {secondaryPrice && secondaryPrice}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CardProducts.propTypes = {
  img1x: PropTypes.string.isRequired,
  img2x: PropTypes.string.isRequired,
  img3x: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  principalPrice: PropTypes.string.isRequired,
  secondaryPrice: PropTypes.string,
  isPromo: PropTypes.bool,
  isCitaFlow: PropTypes.bool,
  appointmentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  appointmentCenter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  countryCurrency: PropTypes.string,
};

CardProducts.defaultProps = {
  secondaryPrice: '',
  isPromo: false,
  isCitaFlow: false,
  appointmentDate: '',
  appointmentCenter: '',
  countryCurrency: '$',
};

export default CardProducts;
