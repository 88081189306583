/* eslint no-trailing-spaces: "error" */
import React from 'react';
import {
  /*   atrato1x,
  atrato2x,
  atrato3x, */
  mercado1x,
  mercado2x,
  mercado3x,
  stripe1x,
  stripe2x,
  stripe3x,
} from '../../assets/images/Footer';
import Box from '../Box';
// import Text from '../Text';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './Footer.module.css';
// import GlobalContext from '../../context/Global/Context';

export const Footer = () => {
  // const globalContext = useContext(GlobalContext);
  // const { country } = globalContext;
  const isMobile = useMediaQuery('(max-width: 1024px)');
  // const inter12 = isMobile ? 12 : 14;
  return (
    <Box className={styles.footerAbsolute}>
      <Box jc={!isMobile ? 'flex-start' : 'center'} fd="row">
        <picture>
          <source
            type="image/webp"
            srcSet={`${stripe1x} 1x, ${stripe2x} 2x, ${stripe3x} 3x`}
          />
          <img
            src={stripe2x}
            alt="Product"
            height={24}
            width={50}
            srcSet={`${stripe1x} 1x, ${stripe2x} 2x, ${stripe3x} 3x`}
          />
        </picture>
        <Box w="auto" p="0 16px">
          <picture className={styles.logoMercado}>
            <source
              type="image/webp"
              srcSet={`${mercado1x} 1x, ${mercado2x} 2x, ${mercado3x} 3x`}
            />
            <img
              src={mercado2x}
              alt="Product"
              height={24}
              width={85}
              srcSet={`${mercado1x} 1x, ${mercado2x} 2x, ${mercado3x} 3x`}
            />
          </picture>
        </Box>
        {/* <picture>
          <source
            type="image/webp"
            srcSet={`${atrato1x} 1x, ${atrato2x} 2x, ${atrato3x} 3x`}
          />
          <img
            src={atrato2x}
            alt="Product"
            height={24}
            width={66}
            srcSet={`${atrato1x} 1x, ${atrato2x} 2x, ${atrato3x} 3x`}
          />
        </picture> */}
      </Box>
      {/* <Box jc={!isMobile ? 'flex-start' : 'center'} fd="row" p="10px 0 32px">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://legal.mymoons.${country}/aviso-de-privacidad`}
        >
          <Text s={inter12}>
            Privacidad
          </Text>
        </a>
        <Text s={inter12} p="0 5px">
          |
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://legal.mymoons.${country}/terminos-y-condiciones`}
        >
          <Text s={inter12}>Términos</Text>
        </a>
      </Box> */}
    </Box>
  );
};

export const FooterThankYou = () => {
  // const globalContext = useContext(GlobalContext);
  // const { country } = globalContext;
  // const isMobile = useMediaQuery('(max-width: 1024px)');
  // const inter12 = isMobile ? 12 : 14;
  // const inter14 = isMobile ? 14 : 16;

  return (
    <Box>
      <Box fd="row">
        <picture>
          <source
            type="image/webp"
            srcSet={`${stripe1x} 1x, ${stripe2x} 2x, ${stripe3x} 3x`}
          />
          <img
            src={stripe2x}
            alt="Product"
            height={24}
            width={50}
            srcSet={`${stripe1x} 1x, ${stripe2x} 2x, ${stripe3x} 3x`}
          />
        </picture>
        <Box w="auto" p="0 16px">
          <picture className={styles.logoMercado}>
            <source
              type="image/webp"
              srcSet={`${mercado1x} 1x, ${mercado2x} 2x, ${mercado3x} 3x`}
            />
            <img
              src={mercado2x}
              alt="Product"
              height={24}
              width={85}
              srcSet={`${mercado1x} 1x, ${mercado2x} 2x, ${mercado3x} 3x`}
            />
          </picture>
        </Box>
        {/* <picture>
          <source
            type="image/webp"
            srcSet={`${atrato1x} 1x, ${atrato2x} 2x, ${atrato3x} 3x`}
          />
          <img
            src={atrato2x}
            alt="Product"
            height={24}
            width={66}
            srcSet={`${atrato1x} 1x, ${atrato2x} 2x, ${atrato3x} 3x`}
          />
        </picture> */}
      </Box>
      {/* <Box fd="row" p="10px 0 32px">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://legal.mymoons.${country}/aviso-de-privacidad`}
        >
          <Text s={inter14} p="0 0">
            Privacidad
          </Text>
        </a>
        <Text s={inter12} p="0 10px">
          |
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://legal.mymoons.${country}/terminos-y-condiciones`}
        >
          <Text s={inter14}>Términos</Text>
        </a>
      </Box> */}
    </Box>
  );
};
