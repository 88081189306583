import React, { useContext, useEffect, useState } from 'react';
// import { monetization } from '../../assets/images/Global';
import styles from './Methods.module.css';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
// import GlobalContext from '../../context/Global/Context';
import MethodsFlow from './components/MethodsFlow';
import totalPriceCarts from '../../hooks/totalCarts';
// import RefoundModal from '../RefoundModal';

const Methods = () => {
  const [refoundModal] = useState(false);
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  // const globalContext = useContext(GlobalContext);
  const { carts } = productContext;
  const totalPrice = totalPriceCarts(carts, false);
  const { hasMonthsFunction } = paymentContext;
  // const { isCitaFlow, country } = globalContext;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;

  useEffect(() => {
    if (totalPrice < 300) {
      hasMonthsFunction(false);
    } else {
      hasMonthsFunction(true);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (refoundModal) {
      $html.style.height = '100vh';
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      return;
    }

    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, [refoundModal]);

  return (
    <div className={styles.container}>
      <MethodsFlow totalPrice={totalPrice} />
      {/* <div className={styles.containerPayment}>
        {isCitaFlow ? (
          <>
            <button
              id="Methods_RefoundModal"
              type="button"
              className={styles.containerPolicy}
              onClick={() => {
                setRefoundModal(true);
              }}
            >
              <img
                width={20}
                height={20}
                src={monetization}
                alt="Monetización"
              />
              <p className={styles.policy}>
                Conoce nuestra política de reembolso
              </p>
            </button>
            {refoundModal && (
              <RefoundModal
                refoundModal={refoundModal}
                setRefoundModal={setRefoundModal}
              />
            )}
          </>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://legal.mymoons.${country}/politica-de-devoluciones`}
            className={styles.containerPolicy}
          >
            <img width={20} height={20} src={monetization} alt="Monetización" />
            <p className={styles.policy}>
              Conoce nuestra política de reembolso
            </p>
          </a>
        )}
      </div> */}
    </div>
  );
};

export default Methods;
