export const setTrackingRudder = (name, data) => {
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.track(name, data);
    }
  } catch {
    // eslint-disable-next-line no-console
    console.log('Error in rudderanalytics');
  }
};

export default setTrackingRudder;
